<script>
export default {
  name: 'Logo',

  props: {
    theme: {
      type: String,
      default: 'default'
    },

    link: {
      type: Boolean,
      default: false
    },

    logo: {
      type: Boolean,
      default: false
    },

    dark: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      name: 'vitrine'
    }
  },

  methods: {
    getName () {
      const name = this.theme !== 'default' ? this.theme : this.name

      if (this.logo) {
        return this.dark ? `${name}-logo-dark` : `${name}-logo`
      } else {
        return this.dark ? `${name}-dark` : name
      }
    }
  }
}
</script>

<template>
  <a
    v-if="link"
    href="/"
    class="logo"
  >
    <img
      :src="`/assets/images/themes/${theme}/svg/${getName()}.svg`"
      class="logo-image"
    >
  </a>
  <div
    v-else
    class="logo"
  >
    <img
      :src="`/assets/images/themes/${theme}/svg/${getName()}.svg`"
      class="logo-image"
    >
  </div>
</template>

<style src="@/assets/styles/themes/default/logo.css"></style>
